import React, {
  FC,
  MutableRefObject,
  useRef,
  useCallback,
  CSSProperties,
  useEffect,
} from 'react';
import { cn } from '@/lib/classNames';
import { useOnKeyup } from '@/hooks/useOnKeyup';
import styles from './PlatformSidebar.module.scss';
import { TRANSITION_DURATION } from './constants';

interface Props {
  closeSidebar: () => void;
  isSidebarOpen: boolean;
  classNames?: string;
  shouldInnerScrollBeLocked?: boolean;
  shouldTriggerSidebarClose?: boolean;
  offsetStyle?: CSSProperties;
  sidebarClassName?: string;
  mode?: PlatformSidebarMode;
  sidebarVhHeight?: PlatformSidebarHeight;
  dataQa?: string;
}

export enum PlatformSidebarMode {
  Aside = 'aside',
  Bottom = 'bottom',
}

export enum PlatformSidebarHeight {
  Vh10 = 10,
  Vh20 = 20,
  Vh30 = 30,
  Vh40 = 40,
  Vh50 = 50,
  Vh60 = 60,
  Vh70 = 70,
  Vh80 = 80,
  Vh90 = 90,
  Vh100 = 100,
  Auto = 'auto',
}

export const PlatformSidebar: FC<Props> = ({
  closeSidebar,
  isSidebarOpen,
  shouldInnerScrollBeLocked = false,
  shouldTriggerSidebarClose = false,
  offsetStyle,
  children,
  classNames,
  sidebarClassName,
  mode = PlatformSidebarMode.Aside,
  sidebarVhHeight = PlatformSidebarHeight.Vh80,
  dataQa = 'platform-sidebar',
}) => {
  const isBottomSidebar = mode === PlatformSidebarMode.Bottom;

  const wrapperRef: MutableRefObject<
    HTMLDivElement | null
  > = useRef<HTMLDivElement | null>(null);
  const overlayRef: MutableRefObject<
    HTMLDivElement | null
  > = useRef<HTMLDivElement | null>(null);

  useOnKeyup({
    keyName: 'Escape',
    callback: closeSidebar,
    condition: isSidebarOpen,
  });

  const handleSidebarClose = useCallback(() => {
    wrapperRef.current?.classList.add(styles.closeModal);
    overlayRef.current?.classList.add(styles.closeOverlay);

    setTimeout(() => {
      wrapperRef.current?.classList.remove(styles.closeModal);
      wrapperRef.current?.classList.remove(styles.closeModalWithDragging);
      overlayRef.current?.classList.remove(styles.closeOverlay);

      closeSidebar();
    }, TRANSITION_DURATION - 100);
  }, [closeSidebar]);

  useEffect(() => {
    if (shouldTriggerSidebarClose && wrapperRef.current) {
      handleSidebarClose();
    }
  }, [shouldTriggerSidebarClose, handleSidebarClose, wrapperRef]);

  return (
    <>
      <div
        data-qa={dataQa}
        className={cn(
          styles.sidebarOverlay,
          {
            [styles.overlayActive]: isSidebarOpen,
            [styles.sidebarOverlayBottom]: mode === PlatformSidebarMode.Bottom,
          },
          classNames,
        )}
        ref={overlayRef}
        onClick={handleSidebarClose}
        role="button"
        tabIndex={0}
        aria-label="Sidebar overlay"
        onKeyDown={(event) => {
          if (event.key === ' ') {
            handleSidebarClose();
          }
        }}
      />
      <div
        data-qa="bottom-sidebar"
        ref={(element) => {
          wrapperRef.current = element;
        }}
        className={cn(
          styles.sidebarWrapper,
          {
            [styles.isScrollLocked]: shouldInnerScrollBeLocked,
            [styles.leftSidebarWrapper]: !isBottomSidebar,
            [styles.bottomSidebarWrapper]: isBottomSidebar,
            [styles.active]: isSidebarOpen,
            [styles[`bottomSidebarHeight-${sidebarVhHeight}`]]: isBottomSidebar,
            [styles.closeModalWithDragging]: shouldTriggerSidebarClose,
          },
          sidebarClassName,
        )}
        style={offsetStyle}
      >
        {children}
      </div>
    </>
  );
};
